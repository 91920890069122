<template>

 <FilterButtons :crm="userInfo.crm" :role="role" :taskList="taskList" @updateParent="data = $event.data; filter = $event.filter"/>

  <v-table
    height="600px"

    fixed-header
    v-if="data.length"
  >
    <thead>
      <tr>
        <th class="text-left">
          № Заявки
        </th>
        <th class="text-left">
          Содержимое заявки
        </th>
        <th v-if="['all', 'managers', 'developers'].includes(filter)" class="text-left">
          Статус
        </th>
        <th v-if="role === 'admin' || role === 'user'" class="text-left">
          Исполнитель
        </th>
        <th class="text-left">
          Дата <span v-if="filter !== 'all'" >{{  dateStatus[filter] }}</span>
        </th>
        <th class="text-left">
          Действия
        </th>
      </tr>
    </thead>
    <tbody>
      <tr style="height: 100px !important;" v-for="(item, index) of data" :key="index">
      <td>{{ item.taskId }}</td>
      <td> <v-btn @click="openTaskContent(item)">Просмотр</v-btn> </td>
      <td v-if="['all', 'managers', 'developers'].includes(filter)">{{ statusList[item.status] }}</td>
      <td v-if="role === 'admin' || role === 'user'">{{ executorObj[item.executor] ? executorObj[item.executor] : 'Исполнитель не назначен' }}</td>
      <td>{{ item.date }}</td>
      <td> <ActionButtons v-bind:item="item"/> </td>
    </tr>
    </tbody>
  </v-table>

  <div v-else style="padding-top: 30px;height: 600px;">Пусто</div>

  <v-dialog v-model="taskView" max-width="1200">
  
              <v-btn class="close-dialog" icon="mdi-close" size="small"
                @click="taskView = false; taskItem = {}"
              ></v-btn>

    <v-card class="p-5" style="padding: 15px; overflow-y: auto;" v-bind:title="`Заявка № ${taskItem.taskId}`">
     
      <p><b>E-mail для обратной связи:</b>{{ taskItem.mail }} </p>
      <p><b>Описание:</b> {{ taskItem.description }}</p>
      <p><b>Скриншоты:</b></p>
              <div v-for="(img, index) of taskItem.imageNames" :key="index" class="img-wrapper mb-2">
                <img style="width: 100%;" v-bind:src= "imgPath + '/' + img"  alt="">
              </div>
            
          </v-card>
      </v-dialog>
</template> 

<script>
import {arrToObj} from '@/utils/arrToObj';
import { mapGetters } from 'vuex';
import ActionButtons from '@/components/taskListComponents/ActionButtons.vue';
import FilterButtons from '@/components/taskListComponents/FilterButtons.vue';


export default{
    name: 'TaskList',
    components: {
    ActionButtons,
    FilterButtons
    },
    data(){
      return{
        imgPath: process.env.VUE_APP_SERVER_URL,
        taskView: false,
        taskItem : {},
        filter: 'unprocess',
        icon: 'justify',
        data: [],
        dateStatus: {unprocess: 'создания', developers: 'начала обработки', managers: 'начала обработки', finished: 'завершения'},
      }
    
    },
    mounted(){
      this.$store.dispatch('getTasksList');  
    },

    

    
    
    methods:{
      openTaskContent(item){
        // console.log(item);
        this.taskView = true;
        this.taskItem = item;
      },
    },
    
    computed: {
      ...mapGetters([
      'statusList',
      'executorList',
      'role',
      'taskList',
      'userInfo',
    ]),
    executorObj() {
      return arrToObj(this.$store.getters.executorList,'sfrId', 'name')
    },
    
  },
}
</script>

<style scoped>
  td{
   text-align: left;
  }
  .close-dialog{
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 10;
  }
</style>