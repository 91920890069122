import {postData} from '../../utils/postData';
// import router from '../../router/index';
export const formModule = {
    state() {
       return{
        formData: {
            mail: '',
            message: '',
            images: [], 
        },
        isDisabled: false,
        isSending: false,
        isAlert: false,

        
       }
    },
    getters: {
        formData: s => s.formData,
        isDisabled: s => s.isDisabled,
        isSending: s => s.isSending,
        disabled: s => s.disabled,
        
    },
    mutations: {
        setFormData(state, payload){
            state.formData = payload;
        },
        setIsDisabled(state, payload){
            state.isDisabled = payload;
        },
        setIsSending(state, payload){
            state.isSending = payload;
        }
    },
    actions: {
        sendData({getters, commit, dispatch}){
            const data = getters.formData;
            data.id = getters.userInfo.id;
            data.method = getters.userInfo.method;

            commit('setIsSending', true);
            postData("/create_task", { data: data }).then((resp) => { 
                commit('setIsAlert', {status: true, text: resp});
                dispatch('getTasksList');  
                commit('setIsSending', false);

                commit('setFormData', {
                    mail: '',
                    message: '',
                    images: [], 
                },)
             })
        }
    },
}