import moment from 'moment';

export async function postData(url = "", data = {}) {
    const key1 = process.env.VUE_APP_KEY_ONE.replaceAll('с', 'c');
    const key2 = process.env.VUE_APP_KEY_TWO.replaceAll('с', 'c');
    let date = moment().unix();
    let hex = await hash(url + '\n'+date+'\n'+key1+'\n'+JSON.stringify(data)+'\n'+key2);    
    let Bearer = 'Bearer '+key1+date+hex;
    const response = await fetch(process.env.VUE_APP_SERVER_URL + url, {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers: {
      "Content-Type": 'application/json; charset=utf-8',
      "authorization": Bearer
    },
    redirect: "follow", // manual, *follow, error
    referrerPolicy: "no-referrer", // no-referrer, *client
    body: JSON.stringify(data), // body data type must match "Content-Type" header
});
return await response.json(); // parses JSON response into native JavaScript objects
}

export async function getData(pathname = "", search = "", data = {}) {
  const url = pathname + search;
  const key1 = process.env.VUE_APP_KEY_ONE.replaceAll('с', 'c');
  const key2 = process.env.VUE_APP_KEY_TWO.replaceAll('с', 'c');
  let date = moment().unix();
  let hex = await hash(pathname + '\n'+date+'\n'+key1+'\n'+JSON.stringify(data)+'\n'+key2);    
  let Bearer = 'Bearer '+key1+date+hex;
  const response = await fetch(process.env.VUE_APP_SERVER_URL + url, {
    method: 'GET',
    headers: {
      "Content-Type": 'application/json; charset=utf-8',
      "authorization": Bearer
    },
});
return await response.json(); // parses JSON response into native JavaScript objects
}

  async function hash(string) {
    const utf8 = new TextEncoder().encode(string);
    const hashBuffer = await crypto.subtle.digest('SHA-256', utf8);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray
    .map((bytes) => bytes.toString(16).padStart(2, '0'))
    .join('');
    return hashHex;
}