import { createStore } from 'vuex'
import { authModule } from './modules/auth'
import { formModule } from './modules/form'
import { alertModule } from './modules/alert'
import { tasksListModule } from './modules/tasksList'
export default createStore({
    modules: {
        authModule,
        formModule,
        alertModule,
        tasksListModule
    }

})