<template>
      <v-col
        class="d-flex tabsContainer"
        cols="12"
      >
        <v-btn-toggle
          v-model="filter"
          width="100%"
          :color="crm === 'foroom' ? '#df771c' : 'rgb(5, 133, 189)'"
          rounded="0"
          group
        >
          <v-btn @click="changeFilter('all')" value="all">
           Все заявки <span >({{ taskList ? taskList.length : '0'}})</span>
          </v-btn>

          <v-btn @click="changeFilter('unprocess')" value="unprocess">
            Необработанные заявки <span >({{ taskList ? taskList.filter(el => el.status === unprocessStatus[role]).length : '0'}})</span>
          </v-btn>

          <v-btn v-if="role === 'admin'" @click="changeFilter('managers')" value="managers">
            Заявки у менеджеров <span >({{ taskList ? taskList.filter(el => [3,6].includes(el.status)).length : '0'}})</span>
          </v-btn>

          <v-btn v-if="role === 'admin'" @click="changeFilter('developers')" value="developers">
            Заявки у разработчиков <span >({{ taskList ? taskList.filter(el => [2,5].includes(el.status)).length : '0'}})</span>
          </v-btn>

          <v-btn v-if="role === 'developer' || role === 'manager'" @click="changeFilter('inWork')" value="inWork">
            Заявки в работе <span >({{ taskList ? taskList.filter(el => el.status === (role === 'developer' ? 5 : 6)).length : '0'}})</span>
          </v-btn>
          <v-btn @click="changeFilter('finished')" value="finished">
            Завершенные заявки <span >({{ taskList ? taskList.filter(el => el.status === 10).length : '0'}})</span>
          </v-btn>
        </v-btn-toggle>
  </v-col>
</template>

<script>
// import { mapGetters } from 'vuex';
export default{
    name: 'FilterButton',
    props: ['role', 'taskList', 'crm'],

    data(){
      return{
        filter: 'unprocess',
        unprocessStatus:{user: 1, admin: 1, developer: 2, manager: 3},
      }
    },
    // computed: {
    //   ...mapGetters([
      
    // ]),},

    methods:{
        changeFilter(val){
        this.filter = val;
        // console.log(val);
        let statuses = {unprocess: [this.unprocessStatus[this.role]], developers: [2, 5], managers: [3, 6], finished: [10], inWork: [this.role === 'developer' ? 5 : 6]};
        if(val === 'all'){
          // this.data = this.taskList;
          this.$emit('updateParent', {data: this.taskList, filter: val});
        }
        else{
          // this.data = this.taskList.filter(el => el.status === statuses[val]);
          this.$emit('updateParent', {data: this.taskList.filter(el => statuses[val].includes(el.status)), filter: val});
        }
      },
    },


    watch:{
      taskList(value){
        // console.log('смена чего то там');
        // console.log(this.filter);
       this.data = value;
       this.changeFilter(this.filter);
      },
    },
}
</script>

<style>
</style>