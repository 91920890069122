<template>
    <v-sheet max-width="700"  class="mx-auto" style="height: 674px; max-height: 674px;">
    <v-form class="d-flex flex-column mb-5" @submit="sendForm($event)">  
        <v-text-field name="email"  v-bind:model-value="formData.mail"  hint="Введите E-mail для обратной связи" id="mail" @change="changeFormData($event.target.id, $event.target.value)" type="email" label="E-mail для обратной связи"></v-text-field>
        <v-textarea id="message" v-bind:model-value="formData.message" @change="changeFormData($event.target.id, $event.target.value, $event.target.dataset)" label="Опишите проблему"></v-textarea>
        <v-btn  class="file-btn" v-bind:class="userInfo.crm"><label class="text-left" for="images">Прикрепить изображение</label></v-btn>
        <input accept="image/*" id="images" @change="changeFormDataImage($event.target.id, $event.target)" label="Добавьте изображение" type="file">
    
        <v-btn v-bind:color="userInfo.crm === 'foroom' ? '#df771c' : 'rgb(5, 133, 189)'" v-bind:disabled="isDisabled" type="submit" class="mt-2" text="Создать заявку"></v-btn>     
    </v-form>

    <v-dialog v-model="isSending" persistent width="auto">
      
      <v-card v-bind:color="userInfo.crm === 'foroom' ? '#df771c' : 'rgb(5, 133, 189)'">
        <v-card-text>
          Пожалуйста подождите
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>


    <AlertComponent/>

         <div v-if="formData.images.length > 0" class="preview d-flex flex-wrap"><v-img class="preview-img flex-grow-0 position-relative  flex-shrink-0" v-for="(image, index ) of formData.images"  :width="150" aspect-ratio="16/9" cover v-bind:src="image" v-bind:key="index" alt=""><button class="pi pi-times remove-btn" @click="removeImage(index)"> </button></v-img></div>
    </v-sheet>
</template>

<script>
 import { mapGetters } from 'vuex';
 import AlertComponent from './AlertComponent.vue';

 export default{
    name: 'FormComponent',
    components:{
    AlertComponent,
},
    mounted(){

    },
    computed: {
      ...mapGetters([
      'formData',
      'isDisabled',
      'isSending',
      'authData',
      'userInfo'
    ])},
    methods:{
        sendForm(e){
            e.preventDefault();

            if(!this.isEmail(this.formData['mail'])){
              this.$store.commit('setIsAlert', {status: true, text:'Введите корректный e-mail'});
            }
            else if(this.formData['message'].length < 30){
              this.$store.commit('setIsAlert', {status: true, text:'Введите более подробное описание проблемы'});
            }
            else if(this.formData['images'].length < 1 || this.formData['images'].length > 5){
              this.$store.commit('setIsAlert', {status: true, text:'Количество изображений должно быть не меньше 1 и не больше 5'});
            }
            else{
              this.$store.dispatch('sendData');
            }
            
        },
        removeImage(index){ 
            this.formData.images.splice(index, 1);
            
            this.$store.commit('setFormData', this.formData);
        },
        changeFormData(type, value){
            this.formData[type] = value;
            this.$store.commit('setFormData', this.formData)
        }, 
        async changeFormDataImage(type, e){
           let image = await this.readFile(e.files[0]);
            
            this.formData[type].push(image);
         
            this.$store.commit('setFormData', this.formData);
        },
        isEmail(value) {
        if (/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/iu.test(value)) return true

        return false;
        },
        readFile(file){
            return new Promise((resolve, reject) => {
                var fr = new FileReader();  
                fr.readAsDataURL(file);
                fr.onload = () => {
                resolve(fr.result )
                };
                fr.onerror = reject;
            });
        },
    }
}
</script>
<style>
.v-input__prepend{
  display: none !important;
}
.preview-img{
    border-radius: 10px;
}
.preview-img:not(:last-child){
    margin-right: 10px;
}

.file-btn label{
    display: flex;
    width: 700px;
    height: 36px;
    justify-content: center;
    align-items: center;
    background-color: #df771c;
    color: #fff;
    cursor: pointer;
}

.file-btn.katris label{
  background-color: rgb(5, 133, 189);
}



#images {
   opacity: 0;
   position: absolute;
   z-index: -1;
}

.remove-btn{
    position: absolute;
    top: 0;
    right: 0;
    color: darkgray;
    border-radius: 100%;
    padding: 5px;
    background-color: rgba(236, 236, 236, 0.9);
}


</style>