<template>
 <v-skeleton-loader
 v-if="!isError"
    class="mx-auto"
    elevation="12"
    max-width="600"
    type="list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line"
  ></v-skeleton-loader>
  <div v-if="isError">
    <ErrorComponent/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ErrorComponent from '@/components/ErrorComponent.vue';

export default{
    name: 'betweenAuth',
    components: {
      ErrorComponent
    },
    mounted(){
        this.$store.dispatch('betweenAuthData');
    },
    computed: {
      ...mapGetters([
      'isError',
    ]),}
}
</script>