import { createRouter, createWebHistory } from 'vue-router';
import BetweenAuth from '../views/BetweenAuth.vue';
import StartPage from '../views/StartPage.vue';
import LoginPage from '../views/LoginPage.vue';
const routes = [{
        path: '/sfr_auth',
        component: BetweenAuth
    },
    {
        path: '/sk_auth',
        component: BetweenAuth
    },
    {
        path: '/',
        component: StartPage
    },
    {
        path: '/login',
        component: LoginPage
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router