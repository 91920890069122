<template>
     <v-tooltip location="top" v-if="(role === 'admin' && item.status === 1) || (role === 'manager' || role === 'developer') && [5, 6].includes(item.status)">
          <template v-slot:activator="{ props }">
            <v-btn v-bind="props" @click="openDialog(item.taskId, 'confirm')" class="mr-2" v-bind:icon="['developer', 'manager'].includes(role) ? 'mdi-check' : 'mdi-close'" size="x-small"></v-btn>
          </template>
          <span>Закрыть заявку</span>
      </v-tooltip>

      <v-tooltip location="top" v-if="(item.status === 10 || item.status === 7) && role !== 'manager' && role !== 'developer'">
        <template v-slot:activator="{ props }">
          <v-btn v-bind="props" @click="openDialog(item.taskId, 'restart')"  class="mr-2" icon="mdi-reload" size="x-small"></v-btn>
        </template>
        <span>Перезапустить заявку</span>
      </v-tooltip>

      <v-tooltip location="top" v-if="(role === 'developer' || role === 'manager') &&  (item.status === 2 || item.status === 3)">
        <template v-slot:activator="{ props }">
          <v-btn v-bind="props" @click="openDialog(item.taskId, 'return_task')"  class="mr-2" icon="mdi-close" size="x-small"></v-btn>
        </template>
        <span>Вернуть администратору</span>
      </v-tooltip>

      <v-tooltip location="top" v-if="(role === 'developer' || role === 'manager') &&  (item.status === 2 || item.status === 3)">
        <template v-slot:activator="{ props }">
          <v-btn v-bind="props" @click="takeTask(item.taskId)"  class="mr-2" icon="mdi-arrow-right" size="x-small"></v-btn>
        </template>
        <span>Взять в работу</span>
      </v-tooltip>

      <v-tooltip v-if="role === 'admin' && [1,2,3].includes(item.status)" location="top" >
          <template v-slot:activator="{ props }">
            <v-btn v-bind="props" class="mr-2" id="menu-activator" v-if="item.status !== 10" v-bind:icon="executorObj[item.executor] ? 'mdi-account-switch' : 'mdi-account-plus'" size="x-small" @click="openDialog(item.taskId, 'dialog')">
        </v-btn>
          </template>
          <span>{{ executorObj[item.executor] ? 'Сменить исполнителя' : 'Назначить исполнителя' }}</span>
      </v-tooltip>

      <v-dialog v-if="role === 'admin'" v-model="dialog" max-width="500">
          <v-card title="Выбрать исполнителя">
            <v-select style="padding: 10px;" :modelValue="defaultExecutor" @update:modelValue="changeExecutor(executorList.find(el => el.name ===$event))" :items="executorList" item-title="name" label="Исполнитель">
              <template v-slot:item="{ props}">
                <v-list-item v-bind="props" ></v-list-item>
              </template>
            </v-select>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                text="Закрыть"
                @click="dialog = false"
              ></v-btn>
            </v-card-actions>
          </v-card>
      </v-dialog>

      <v-dialog v-model="confirm" max-width="500">
          <v-card title="Вы уверены, что хотите закрыть заявку?">
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                text="Да"
                @click="closeTask()"
              ></v-btn>
              <v-btn
                text="Нет"
                @click="confirm = false"
              ></v-btn>
            </v-card-actions>
          </v-card>
      </v-dialog>

      <v-dialog v-model="restart" max-width="500">
        <v-card title="Вы уверены, что хотите перезапустить заявку?">
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
          text="Да"
          @click="reloadTask()"
          ></v-btn>
          <v-btn
          text="Нет"
          @click="restart= false"
          ></v-btn>
        </v-card-actions>
       
          </v-card>
      </v-dialog>

      <v-dialog v-model="return_task" max-width="500">
        <v-card title="Вы уверены, что хотите вернуть заявку?">
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
          text="Да"
          @click="rеturnTask()"
          ></v-btn>
          <v-btn
          text="Нет"
          @click="return_task = false"
          ></v-btn>
        </v-card-actions>
       
          </v-card>
      </v-dialog>


        <!-- <v-tooltip location="top">
          <template v-slot:activator="{ props }">
            <v-btn v-bind="props" class="mr-2" icon="mdi-arrow-right" size="x-small"></v-btn>
          </template>
          <span>Перейти в чат</span>
        </v-tooltip> -->
</template>
<script>
import {arrToObj} from '@/utils/arrToObj';
import { mapGetters } from 'vuex';
export default{
    name: 'ActionButtons',
    props: ['item'],
    
    data(){
      return{
        dialog: false,
        confirm: false,
        restart: false,
        return_task: false,
        isExecutorSelector: false,
        defaultExecutor: 'Не выбрано',
      } 
    },
    methods:{
      openDialog(id, dialogName){
        this[dialogName] = true;
        this.processTask = id;
        // this.isExecutorSelector = true;
      },
      changeExecutor(executor){
        let action = '';
        if(this.item.executor === 0){
          action = 'add_executor'
        }
        else{
          action = 'change_executor'
        }

        const status = executor.role === 'developer' ? 2 : 3;
        this.$store.dispatch('changeTask', {executor: executor.sfrId, taskId: this.processTask, status: status, action: action});
        this.processTask = 0;
        this.dialog = false;
      },
      closeTask(){
        let action = 'close_task';
        this.$store.dispatch('changeTask', { taskId: this.processTask, status: 10, action: action});
        this.confirm = false;
        this.processTask = 0;
      },
      reloadTask(){
        let action = 'reload_task';
        this.$store.dispatch('changeTask', { taskId: this.processTask, status: 1, executor: 0, action: action});
        this.restart = false;
        this.processTask = 0;
      },
      rеturnTask(){
        let action = 'return_task';
        this.$store.dispatch('changeTask', { taskId: this.processTask, status: 7, executor: 0, action: action});
        this.return_task = false;
        this.processTask = 0;
      },
      takeTask(id){
        let action = 'task_in_work';
        this.$store.dispatch('changeTask', { taskId: id, status: this.role === 'developer' ? 5 : 6, action: action});
      }
    },
    computed: {
      ...mapGetters([
      'executorList',
      'userInfo',
      'role'
    ]),
    executorObj() {
      return arrToObj(this.$store.getters.executorList,'sfrId', 'name')
    },
    
  },
}
</script>
<style>


</style>