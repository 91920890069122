import {postData} from '../../utils/postData';
import {arrToObj} from '../../utils/arrToObj';
export const tasksListModule = {
    state() {
        return {
            taskList: [],
            statusList: [],
            executorList: [],
            role: '',
        }
    },
    getters: {
        taskList: s => s.tasksList,
        statusList: s => s.statusList,
        executorList: s => s.executorList,
        role: s => s.role,
    },
    mutations: {
        setTaskList(state, payload) {
            state.tasksList = payload;
        },
        setRole(state, payload) {
            state.role = payload;
        },
        setStatusList(state, payload){
            state.statusList = payload;
        },
        setExecutorList(state, payload){
            state.executorList = payload;
        },

    },
    actions: {
        getTasksList({commit, getters}){
            const id = getters.userInfo.id;
            const fold = getters.fold;
            postData("/tasks_list", { id: id, fold: fold }).then((resp) => {
                commit('setRole', resp.role);
                if(resp.list ){
                    for(let item of resp.list){
                        item.name = 'Заявка №' + item.taskId;
                    }
                    commit('setTaskList', resp.list);
                }
               
                commit('setStatusList', arrToObj(resp.statusList, 'status', 'value'));
                commit('setExecutorList', resp.executorList);
            })
           
        },
        changeTask({commit, getters}, payload){
            const params = Object.assign({}, payload, {taskId: undefined, action: undefined});
            delete params.taskId;
            delete params.action;
            postData("/change_task", {taskId: payload.taskId, params: params, action: payload.action}).then((resp) => {
                if(resp === 'ok'){
                    // console.log(resp);
                    const taskList = [...getters.taskList];
                    const changeTask = taskList.find(el => el.taskId == payload.taskId);
                    for(let key in params){
                        changeTask[key] = params[key];
                    }
                    if(changeTask.status === 7){
                        taskList.splice(taskList.indexOf(changeTask), 1);
                    }
                    commit('setTaskList', taskList);
                }
            });

        }
    }

}