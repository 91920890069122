<template>
   <v-alert
      v-model="store.getters.authError.val"
      border="start"
      close-label="Close Alert"
      color="red"
      class="mb-5"
      title="Внимание"
      variant="tonal"
      closable
    >
    {{ store.getters.authError.text }}
    </v-alert>


  <h2 class="text-h5 mb-2">Войти в Тех. Поддержку</h2>
  <v-dialog v-model="store.getters.isSending" persistent width="auto">
      <v-card color="#df771c">
        <v-card-text>
          Пожалуйста подождите
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>


    <form  @submit.prevent="submit">

      
      <v-text-field
        v-model="login.value.value"
        type="text"
        label="Логин"
        required
      ></v-text-field>
  
      <v-text-field
        v-model="password.value.value"
        :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
        :type="show ? 'text' : 'password'"
        label="Пароль"
        @click:append="show = !show"
        required
      ></v-text-field>
  
      <v-select
        v-model="crm.value.value"
        :error-messages="crm.errorMessage.value"
        :items="items"
        required
        label="Сервис"
      ></v-select>
  
      <v-btn color="#df771c" class="me-4" type="submit"> Войти </v-btn>
  
      <!-- <v-btn @click="handleReset"> Очистить </v-btn> -->
    </form>

 

  </template>
  
  <script setup>
 import { useStore } from 'vuex'
   import md5 from '@/utils/md5'
    import { ref } from 'vue'
    import { useField, useForm } from 'vee-validate';

    const store = useStore();
    let show = ref(false);
    const { handleSubmit } = useForm({
     
    })
    const login = useField('login')
    const password = useField('password')
    const crm = useField('crm')
  
    const items = ref(['SALE.FOROOM', 'SALE.KATRIS']);
  
    const submit = handleSubmit(values => {
      store.commit('setIsSending', true);
      values.crm = values.crm === 'SALE.FOROOM' ? 'foroom' : 'katris';
      values.password = values.crm === 'foroom' ? md5(values.password) : values.password;
      store.dispatch('auth', values);

    });

  </script>
  