import {getData, postData} from '../../utils/postData';
import router from '../../router/index';
export const authModule = {
    state() {
        return {
            isBetweenAuth: false,
           isAuth: false,
           isError: false,
           authError: {val: false, text: ''},
           authData: {},
           userInfo:{
            fold: '',
            id: '',
            crm: '0',
            method: '',
           },
           login: '',
           password: '',
        }
    },
    getters: {
        isAuth: s => s.isAuth,
        isBetweenAuth: s => s.isBetweenAuth,
        authError: s => s.authError,
        isError: s => s.isError,
        authData: s => s.authData,
        password: s => s.password,
        login: s => s.login,
        userInfo: s => s.userInfo,
    },
    mutations: {
        setAuthData(state, payload) {
            state.authData = payload;
        },
        setIsAuth(state, payload) {
            state.isAuth = payload;
        },
        setIsBetweenAuth(state, payload) {
            state.isBetweenAuth = payload;
        },
        setIsError(state, payload) {
            state.isError = payload;
        },
        setAuthError(state, payload) {
            state.authError = payload;
        },
        setLogin(state, payload) {
            state.login = payload;
        },
        setPassword(state, payload) {
            state.password = payload;
        },
        setUserInfo(state, payload) {
            state.userInfo = payload;
        },
        setUserInfoKey(state, payload) {
            state.userInfo[payload.key] = payload.value;
        },

    },
    actions: {
       async betweenAuthData({ commit }) {
        commit('setIsBetweenAuth', true);
              getData(window.location.pathname, window.location.search).then((res) => {
                if(res.errors.length || res.data.error){
                    commit('setIsAuth', false);
                    commit('setIsError', true);
                }
                else{
                    let data = res.data;
                    if(res.data.account){
                        data = res.data.account;
                    }
                     
                    commit('setIsAuth', true);
                    commit('setAuthData', data);
                    sessionStorage.setItem('authData', JSON.stringify(data));
                    router.push('/');
                }  
            });
        },
        isAuthData({commit, getters}){
            const isBetweenAuth = getters.isBetweenAuth;
            let data = sessionStorage.getItem('authData');
            let crm = sessionStorage.getItem('crm');
            let userInfo = {};
            if(data && crm){
                data = JSON.parse(data);
                commit('setAuthData', data);
                commit('setIsAuth', true);  
                userInfo = {
                    id: data.id,
                    fold: data.fold,
                    method: data.method,
                    crm: crm,
                }
                commit('setUserInfo', userInfo); 
            }
            else{           
                if(!isBetweenAuth){
                    router.push('/login');
                }
               
            }
        },
        auth({commit}, payload){
            const crm = payload.crm;
            let katrisData = null;
            postData("/auth", { data: payload }).then((resp) => {  
                commit('setIsSending', false);
                if(resp.status == 'OK' && typeof(resp.data) != 'undefined'){
                    commit('setAuthError', {val: false, text: ''});
                    switch (crm) {
                        case 'foroom':
                            commit('setAuthData', resp.data.account);
                            sessionStorage.setItem('authData', JSON.stringify(resp.data.account));
                            commit('setIsAuth', true);
                            router.push('/');
                            break;
                        case 'katris':
                            katrisData = JSON.parse(resp.data);
                            commit('setAuthData', katrisData.user);
                            sessionStorage.setItem('authData', JSON.stringify(katrisData.user));
                            commit('setIsAuth', true);
                            router.push('/');
                            break;
                        default:
                            break;
                    }
                    sessionStorage.setItem('crm', crm);
                    
                }
                else if(resp.status == 'FAIL'){
                    commit('setAuthError', {val: true, text: resp.errors[0].descr})
             
                }
               
               
            }).catch((err) =>{
                console.log(err);
                commit('setIsSending', false);
                commit('setAuthError', {val: true, text: 'Ошибка сервера! Попробуйте снова!'})
            })
        }
    }
}