<template>
    <h2 class="font-weight-medium mb-5">
        Войдите через один из сервисов
    </h2>
    <div class="d-flex justify-center">
        <v-btn color="#df771c" class="px-0 py-0 sfr mr-5 "><a class="sfr" href="https://sale.foroom.ru/">SALE FOROOM</a></v-btn>
        <v-btn color="#0585bd" class="px-0 py-0 sk "><a class="sk" href="https://sale.katris.ru/">SALE KATRIS</a></v-btn> 
        <v-btn color="#0585bd" class="px-0 py-0 sk "><a class="sk" href="/login">Авторизация</a></v-btn> 
    </div>
</template>

<script>
export default{
    name: 'ReturnComponent',
}
</script>



<style>
.sfr .v-btn__content, .sk .v-btn__content{
    height: 100% !important;
    align-items: center;
}

.sfr a, .sk a{
    padding: 5px;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    text-decoration: none;
    border-radius: 8px;
    font-weight: 700;
    color: #f7f7f7;
}

/* .sfr{
    background-color: #df771c;
    color: #f7f7f7;
}

.sk{
    background-color: #0585bd;
    color: #f7f7f7;
} */

/* .sk{

} */
</style>