<template>
    <v-dialog @click="this.$store.commit('setIsAlert', {status: false, text: ''})" v-model="isAlert.status" width="500">

          <v-card title="Внимание!">
            <v-card-text>
                {{ isAlert.text }}
           </v-card-text>
      
            <v-card-actions>
              <v-spacer></v-spacer>
      
              <v-btn
                text="Ок"
                @click="this.$store.commit('setIsAlert', {status: false, text: ''})"
              ></v-btn>
            </v-card-actions>
          </v-card>
       
      </v-dialog>
</template>

<script>
 import { mapGetters } from 'vuex';
 export default{
    name: 'AlertComponent',
    computed: {
      ...mapGetters([
      'isAlert'
    ])},
 }
 </script>