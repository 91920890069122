export const alertModule = {
    state() {
       return{
        isAlert:{
            status: false,
            text: '',
        }
        }
    },
    getters: {
        isAlert: s => s.isAlert,
    },
    mutations: {
        setIsAlert(state, payload){
            state.isAlert = payload;
        }
    },
    actions: {},
}