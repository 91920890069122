<template>
  <div v-if="isAuth" v-bind:class="authData.method">
   <div class="d-flex">
          <h3 class="font-weight-medium mb-1 mr-2 text-left" v-if="isAuth">Компания: {{ userInfo.crm == 'foroom' ? authData.company : authData.oficialName }} </h3>
          <h3 class="font-weight-medium mb-5 text-left" v-if="isAuth">Сотрудник: {{ authData.name ? authData.name : 'Имя не указано' }}</h3>
        </div>
    <v-card>
      <v-toolbar :color="userInfo.crm === 'foroom' ? '#df771c' : 'rgb(5, 133, 189)'">
       
        <template v-slot:extension>
          <v-tabs v-model="tab"  :bg-color="userInfo.crm === 'foroom' ? '#df771c' : 'rgb(5, 133, 189)'">
            <v-tab value="one">{{role === 'admin' ?  'Заявки' : 'Мои заявки'}}</v-tab>
            <v-tab v-if="role === 'user' || role === 'admin'"  value="two">Создать <span v-if="role === 'admin'">&nbsp; Тестовую &nbsp;</span> Заявку</v-tab>
            <v-tab v-if="role === 'admin'" value="three">Учетные записи</v-tab>
          </v-tabs>
        </template>
      </v-toolbar>

      <!-- <v-card-text> -->
        <v-window v-model="tab">
          <v-window-item value="one">
            <TaskList/>
          </v-window-item>

          <v-window-item  value="two">
            <h2 class="font-weight-medium mb-3"> Заявка на техподдержку </h2>
            <FormComponent/>
          </v-window-item>

          <v-window-item value="three">
            <h2>Раздел в стадии разработки</h2>
          </v-window-item>
        </v-window>
    </v-card>

  </div>
  <div v-else>
    <ReturnComponent/>
  </div>
</template>

<script>
 import { mapGetters } from 'vuex';
 import FormComponent from '@/components/FormComponent.vue';
 import ReturnComponent from '@/components/ReturnComponent.vue';
 import TaskList from '@/components/taskListComponents/TaskList.vue';
  export default{
      name: 'StartPage',
      components:{
        FormComponent,
        ReturnComponent,
        TaskList
      },
      data: () => ({
        tab: null,
      }),
    
      computed: {
        ...mapGetters([
        'isAuth',
        'authData',
        'userInfo',
        'role'
      ]),
    
      },
      mounted(){
          this.$store.dispatch('isAuthData');
        
      }
  }
</script>
<style>
  .tabs{
    background-color: #df771c;
  }
</style>
